<template>
  <div class="bg" v-if="show" @click="close">
    <div
      class="login-wrapper animation-big"
      @click.stop.prevent="() => {}"
      @keypress="keyListener"
    >
      <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      <!-- <span class="title">登录川云活动平台</span> -->
      <div class="logo"></div>
      <div class="login-way-module module-mobile" v-if="way == 0">
        <!-- <div class="icon"></div> -->
        <!-- <span class="tab">INVITATION CODE</span> -->
        <div class="mobile-input">
          <input v-model="mobile" placeholder="请输⼊⼿机号" />
        </div>
        <div class="code-input-wrapper">
          <div class="code-input">
            <input v-model="code" placeholder="请输⼊验证码" />
          </div>
          <div class="code-action cursor-pointer" @click.stop.prevent="getCode">
            <span>{{ isCounting ? `${countTime}s后重发` : "获取验证码" }}</span>
          </div>
        </div>
        <div class="privacy-wrapper">
          <img
            class="privacy-agree"
            v-if="isPrivacyAgree"
            :src="$ossRes('/index/icon_checked.png')"
            @click.stop.prevent="isPrivacyAgree = false"
          />
          <div
            v-if="!isPrivacyAgree"
            class="privacy-box"
            @click.stop.prevent="isPrivacyAgree = true"
          ></div>
          <span>我已阅读并同意</span>
          <span class="active" @click.stop.prevent="openPrivacy(1)"
            >《用户协议》</span
          >
          <span class="active" @click.stop.prevent="openPrivacy(2)"
            >《隐私政策》</span
          >
        </div>
        <div class="login-action cursor-pointer" @click.stop.prevent="submit">
          <span>登录</span>
        </div>
        <div class="line-wrapper">
          <div class="line"></div>
          <span>第三方账号登录</span>
          <div class="line"></div>
        </div>
        <div class="other-way">
          <div
            class="wechat cursor-pointer"
            @click.stop.prevent="changeWay(1)"
          ></div>
        </div>
      </div>
      <div class="login-way-module module-wechat" v-if="way == 1">
        <span class="tips">{{
          $utils.isMobile().wechat
            ? "长按图片识别小程序码登录"
            : "请使用微信扫描小程序码登录"
        }}</span>
        <div class="qrcode-wrapper">
          <img class="qrcode" :src="qrcode" />
        </div>
        <div
          class="back-action cursor-pointer"
          @click.stop.prevent="changeWay(0)"
        >
          <span>返回</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { rule } from "@/assets/js/utils";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    projectcode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mobile: "",
      code: "",
      countTime: 60,
      isCounting: false,
      way: 0, // 0:手机号码，1:微信
      qrcode: "",
      qrcode_scene: "",
      isPrivacyAgree: true,
    };
  },
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.qrcode_scene = Date.now();
          this.initQrcode();
        } else {
          this.way = 0;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    openPrivacy(t) {
      if (t == 1) {
        window.open(`https://external.333f.com/static/CyUser.html`);
      } else if (t == 2) {
        window.open(`https://external.333f.com/static/CyPrivacy.html`);
      }
    },
    keyListener(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    changeWay(k) {
      this.way = k;
      if (k === 1) {
        this.internalRequest();
      }
    },
    internalRequest() {
      const _this = this;
      _this.$http
        .post("/user/wxAuthNew", {
          timestamp: _this.qrcode_scene,
        })
        .then((res) => {
          if (res.code == 200 && res.data && res.data.user_info) {
            _this.$storage.setToken(res.data.user_info.token);
            _this.$store.dispatch("saveUserInfo", res.data.user_info);
            _this.$store.dispatch("saveConfig", res.data.config);
            _this.$toast.success("登录成功");
            _this.close();
            _this.$emit("loginsuccess");
          } else {
            setTimeout(() => {
              if (_this.way === 1) {
                _this.internalRequest();
              }
            }, 1500);
          }
        });
    },
    initQrcode() {
      const _this = this;
      _this.$http
        .get(
          `/mecard/wechat/getQRCode?scene=${
            process.env.NODE_ENV == "development" ? "1_" : "_"
          }1_${_this.qrcode_scene}_0${
            sessionStorage.getItem("share_id")
              ? "_" + sessionStorage.getItem("share_id")
              : ""
          }&pages=subpackage_login_cy/index/index`
        )
        .then((res) => {
          if (res) {
            _this.qrcode = _this.$utils.arrayBufferToBase64(res);
          }
        });
    },
    countDownBegin() {
      this.isCounting = true;
      this.countDown();
    },
    countDown() {
      if (this.countTime > 0) {
        this.countTime = this.countTime - 1;
        setTimeout(() => {
          this.countDown();
        }, 1000);
      } else {
        this.isCounting = false;
        this.countTime = 60;
      }
    },
    getCode() {
      if (this.isCounting) {
        return;
      }
      if (!this.mobile) {
        this.$toast("请输入手机号码");
        return;
      }
      if (!rule(this.mobile, "phone")) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      this.$http
        .post("/user/sendMsg", {
          phone: this.mobile,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast.success("发送成功");
            this.countDownBegin();
          }
        });
    },
    submit() {
      if (!this.mobile) {
        this.$toast("请输入手机号码");
        return;
      }
      if (!rule(this.mobile, "phone")) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      if (!this.code) {
        this.$toast("请输入验证码");
        return;
      }
      if (!this.isPrivacyAgree) {
        this.$toast("请阅读并同意《用户协议》《隐私政策》");
        return;
      }
      const requestParam = {
        phone: this.mobile,
        code: this.code,
      };
      if (sessionStorage.getItem("share_id")) {
        requestParam["share_id"] = sessionStorage.getItem("share_id");
      }
      this.$http.post("/user/login", requestParam).then((res) => {
        if (res.code == 200) {
          if (res.data.user_info["is_black"] == 1) {
            // 封禁用户，禁止进入活动
            this.$toast.fail("账号被封禁，登录失败");
            return;
          }
          this.$storage.setToken(res.data.user_info.token);
          this.$store.dispatch("saveUserInfo", res.data.user_info);
          this.$store.dispatch("saveConfig", res.data.config);
          this.$toast.success("登录成功");
          this.close();
          this.$emit("loginsuccess");
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(46, 46, 46, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .login-wrapper {
    width: 1134px;
    height: 1030px;
    box-sizing: border-box;
    padding: 136px 253px 80px 253px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    // background: url(#{$PublicEnv}/pop/login/bg.png);
    // background-size: 100% 100%;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 24px;
      background: linear-gradient(92deg, #9865ff 0%, #3b70c9 56%, #1fe9ff 99%);
    }
    .close {
      width: 40px;
      height: 38px;
      background: url(#{$PublicEnv}/index/close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 28px;
      top: 50px;
    }
    .title {
      letter-spacing: 4px;
      font-size: 52px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      flex-shrink: 0;
    }
    .logo {
      flex-shrink: 0;
      width: 213.5px;
      height: 102.5px;
      background: url(#{$PublicEnv}/activity/top_logo_3_black.png);
      background-size: 100% 100%;
    }
    .login-way-module {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .icon {
      margin-top: 0px;
      width: 240px;
      height: 240px;
      background: url(#{$PublicEnv}/pop/login/icon.png);
      background-size: 100% 100%;
      flex-shrink: 0;
    }
    .tab {
      margin-top: -30px;
      font-size: 32px;
      font-family: DIN-Regular-, DIN-Regular;
      font-weight: normal;
      color: #ffffff;
      flex-shrink: 0;
      opacity: 0.1;
      letter-spacing: 8px;
    }
    .mobile-input {
      flex-shrink: 0;
      margin-top: 50px;
      width: 628px;
      height: 96px;
      // border: 2px solid rgba(40, 200, 188, 1);
      background: linear-gradient(
        90deg,
        rgba(138, 138, 138, 0.5),
        rgba(255, 255, 255, 0)
      );
      border-radius: 30px;
      box-sizing: border-box;
      padding: 0 34px;
      display: flex;
      align-items: center;
      margin-top: 68px;
      input {
        background: none;
        outline: none;
        border: 0;
        margin: 0;
        width: 100%;
        font-size: 36px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: rgba(100, 100, 100, 1);
        &::placeholder {
          color: rgba(100, 100, 100, 1);
        }
      }
    }
    .privacy-wrapper {
      flex-shrink: 0;
      margin-top: 24px;
      margin-bottom: -36px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .privacy-box {
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        border: 1px solid grey;
        margin-right: 14px;
        border-radius: 50%;
        cursor: pointer;
      }
      .privacy-agree {
        width: 36px;
        margin-right: 10px;
        cursor: pointer;
      }
      span {
        flex-shrink: 0;
        font-size: 28px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #bababa;
        &.active {
          color: #3c71ff;
          cursor: pointer;
        }
      }
    }
    .code-input-wrapper {
      flex-shrink: 0;
      margin-top: 48px;
      width: 628px;
      height: 96px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .code-input {
        flex: 1;
        height: 100%;
        margin-right: 44px;
        background: linear-gradient(
          90deg,
          rgba(138, 138, 138, 0.5),
          rgba(255, 255, 255, 0)
        );
        box-sizing: border-box;
        padding: 0 34px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        input {
          background: none;
          outline: none;
          border: 0;
          margin: 0;
          width: 100%;
          font-size: 36px;
          font-family: Source Han Sans CN-Light, Source Han Sans CN;
          font-weight: 300;
          color: rgba(100, 100, 100, 1);
          &::placeholder {
            color: rgba(100, 100, 100, 1);
          }
        }
      }
      .code-action {
        flex-shrink: 0;
        width: 258px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(60, 113, 255, 0.11);
        box-sizing: border-box;
        border: 2px solid rgba(60, 113, 255, 1);
        border-radius: 16px;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #3c71ff;
        }
      }
    }
    .login-action {
      flex-shrink: 0;
      margin-top: 64px;
      width: 628px;
      height: 108px;
      border-radius: 80px;
      background: linear-gradient(
        90deg,
        rgba(83, 0, 198, 1),
        rgba(68, 44, 246, 1),
        rgba(33, 175, 253, 1)
      );
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 2px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 628px;
        height: 60px;
        background: url(#{$PublicEnv}/detail/action_shadow.png);
        background-size: 100% 100%;
        bottom: -32px;
        left: 50%;
        margin-left: -314px;
      }
    }
    .line-wrapper {
      flex-shrink: 0;
      margin-top: 70px;
      display: flex;
      width: 100%;
      height: 36px;
      justify-content: space-between;
      align-content: center;
      position: relative;
      .line {
        flex: 1;
        height: 0;
        margin-top: 25px;
        border-top: 1px dashed rgba(186, 186, 186, 1);
      }
      span {
        flex-shrink: 0;
        font-size: 32px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #bababa;
        z-index: 10;
        margin: 0 20px;
      }
    }
    .other-way {
      flex-shrink: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      div {
        width: 94px;
        height: 92px;
        &.wechat {
          background: url(#{$PublicEnv}/pop/login/wechat_2.png);
          background-size: 100% 100%;
        }
      }
    }
    .tips {
      font-size: 32px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #bbbbbb;
      margin-top: 80px;
    }
    .qrcode-wrapper {
      width: 308px;
      height: 308px;
      border-radius: 50%;
      box-sizing: border-box;
      margin-top: 18px;
      overflow: hidden;
      .qrcode {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: url(#{$PublicEnv}/loading.gif) no-repeat center;
        overflow: hidden;
      }
    }
    .back-action {
      margin-top: 58px;
      width: 628px;
      height: 108px;
      border-radius: 60px;
      background: linear-gradient(
        90deg,
        rgba(83, 0, 198, 1),
        rgba(68, 44, 246, 1),
        rgba(33, 175, 253, 1)
      );
      line-height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #ffffff;
      }
      &::after {
        content: "";
        position: absolute;
        width: 628px;
        height: 60px;
        background: url(#{$PublicEnv}/detail/action_shadow.png);
        background-size: 100% 100%;
        bottom: -32px;
        left: 50%;
        margin-left: -314px;
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    .login-wrapper {
      width: 3159px;
      height: 3820px;
      box-sizing: border-box;
      padding: 563px 179px 271px 179px;
      .close {
        width: 154px;
        height: 154px;
        right: 87px;
        top: 123px;
      }
      .title {
        font-size: 184px;
      }
      .logo {
        flex-shrink: 0;
        width: 650px;
        height: 312px;
      }
      .icon {
        width: 1024px;
        height: 1024px;
      }
      .tab {
        font-size: 113px;
      }
      .mobile-input {
        flex-shrink: 0;
        margin-top: 384px;
        width: 100%;
        height: 328px;
        padding: 0 76.8px;
        display: flex;
        align-items: center;
        input {
          font-size: 113px;
          height: auto;
        }
      }
      .privacy-wrapper {
        margin-top: 96px;
        margin-bottom: -144px;
        .privacy-box {
          width: 128px;
          height: 128px;
          margin-right: 56px;
          cursor: pointer;
        }
        .privacy-agree {
          width: 144px;
          margin-right: 40px;
        }
        span {
          font-size: 112px;
        }
      }
      .code-input-wrapper {
        margin-top: 194.56px;
        width: 100%;
        height: 328.4px;
        .code-input {
          margin-right: 61.44px;
          padding: 0 76.8px;
          display: flex;
          align-items: center;
          input {
            font-size: 113px;
            height: auto;
          }
        }
        .code-action {
          flex-shrink: 0;
          width: 865.28px;
          height: 100%;
          border-radius: 41px;
          span {
            font-size: 113px;
          }
        }
      }
      .login-action {
        margin-top: 302.08px;
        width: 2102px;
        height: 358px;
        border-radius: 168.96px;
        span {
          font-size: 184px;
          letter-spacing: 10.24px;
        }
      }
      .line-wrapper {
        flex-shrink: 0;
        margin-top: 230px;
        height: 92.16px;
        .line {
          flex: 1;
          height: 0;
          margin-top: 60px;
          border-top: 1px dashed rgba(186, 186, 186, 1);
        }
        span {
          flex-shrink: 0;
          font-size: 113px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #bababa;
          z-index: 10;
          margin: 0 60px;
        }
      }
      .other-way {
        margin-top: 160.16px;
        div {
          width: 281.6px;
          height: 281.6px;
        }
      }
      .tips {
        font-size: 123px;
        margin-top: 328px;
      }
      .qrcode-wrapper {
        width: 1440px;
        height: 1440px;
        padding: 60px;
        margin-top: 174px;
      }
      .back-action {
        margin-top: 162px;
        width: 2102px;
        height: 358px;
        border-radius: 205px;
        span {
          font-size: 184px;
        }
      }
    }
  }
}
</style>
